import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content", "input"]

  connect() {
    this.content = document.querySelector(this.data.get("content-selector"));
  }

  toggle() {
    if (this.hasContentTarget) {
      this.contentTargets.forEach((content) => {
        this.toggleContent(content);
      });
    }

    if (this.hasInputTarget) {
      this.inputTargets.forEach((input) => {
        this.toggleInput(input);
      });
    }

    if (this.content) {
      this.toggleContent(this.content);
    }
  }

  toggleInput(input) {
    input.disabled = !input.disabled
  }

  toggleContent(content) {
    if (content.style.display === "none") {
      content.style.display = "block";
    } else {
      content.style.display = "none";
    }
  }
}
