import { SharedController } from "./shared_controller";

export default class extends SharedController {
  static targets = [
    "spinnerBtn",
    "autoSubmit",
    "disabledContent",
    "enabledContent",
  ]

  initialize() {
    this.setExisting();
    this.submittedCount = 0;
    this.submitInput = this.element.querySelector("[type='submit']");
  }

  connect() {
    if (this.data.get("disabled") === "true") {
      this.disable();
    } else {
      this.enable();
    }

    if (this.existingForm) {
      this.existingForm.replaceWith(this.element);
    }

    if (this.hasSpinnerBtnTarget) {
      this.initSpinners();
    }
  }

  submit() {
    Rails.fire(this.element, 'submit');
  }

  autoSubmit(event) {
    clearTimeout(this.timer);
    const count = this.submittedCount;

    if (event.currentTarget.value !== "") {
      this.timer = setTimeout(() => {
        if (!this.disabled && this.submittedCount === count) {
          if (this.hasSpinnerBtnTarget) {
            this.addSpinnerTo(this.spinnerBtnTarget);
          }

          Rails.fire(this.element, 'submit');
          this.disable();
        }
      }, 2000);
    }
  }

  submitComplete() {
    this.submittedCount += 1;
  }

  enable() {
    this.disabled = false;

    if (this.submitInput) {
      this.submitInput.disabled = false;
    }

    this.disabledContentTargets.forEach((el) => {
      el.style.display = "none";
    });

    this.enabledContentTargets.forEach((el) => {
      el.style.display = "inline-block";
    });

    this.removeSpinners();
  }

  disable() {
    this.disabled = true;

    if (this.submitInput) {
      this.submitInput.disabled = true;
    }

    this.disabledContentTargets.forEach((el) => {
      el.style.display = "inline-block";
    });

    this.enabledContentTargets.forEach((el) => {
      el.style.display = "none";
    });
  }

  error() {
    const alert = { error: this.data.get("error") };

    this.renderAlert(alert);
  }

  success() {
    const alert = { success: this.data.get("success") };

    this.renderAlert(alert);
  }

  replace(event) {
    const [data, status, xhr] = event.detail;

    var html = xhr.response;

    this.element.outerHTML = html;
  }

  setExisting() {
    const existingForm = document.getElementById(this.element.id);

    if (existingForm !== this.element) {
      console.log("Found Duplicate form per ID - form_controller.js")
      this.existingForm = existingForm;
    }
  }

  remoteGet(event) {
    var url = event.currentTarget.dataset.url
    var id = event.currentTarget.value

    Rails.ajax({
      url: url + id,
      type: 'GET',
      complete: (xhr, _) => {
        this.element.outerHTML = xhr.response;
      }
    });
  }

  initSpinners() {
    this.spinnerBtnTargets.forEach((btn) => {
      btn.addEventListener("click", () => {
        this.addSpinnerTo(btn);
      });
    });
  }

  renderErrors(event) {
    const [data] = event.detail;

    const fields = Object.keys(data);

    fields.forEach((field) => {
      this.renderFieldError(field, data[field]);
    });
  }

  addSpinnerTo(el) {
    el.classList.add("spinner");
  }

  removeSpinners() {
    this.element.querySelectorAll(".spinner").forEach((el) => {
      el.classList.remove("spinner");
    });
  }
}
