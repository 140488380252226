import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    this.container = this.element
  }

  append(event) {
    const [data, status, xhr] = event.detail;

    this.container.insertAdjacentHTML("beforeend", xhr.response);
  }
}
