import { Controller } from "stimulus";

export class SharedController extends Controller {
  renderAlert(alertObject) {
    const type = Object.keys(alertObject)[0];
    const message = alertObject[type];

    const template = document.querySelector("template.alert");

    if (template !== null) {
      const clone = template.content.cloneNode(true);

      // replace className
      const typeSelector = ".alert-type";
      const typeEl = clone.querySelector(typeSelector);
      typeEl.classList.remove(typeSelector);
      typeEl.classList.add(type);

      clone.querySelector(".alert-message").textContent = message;

      // make a .alerts container
      const el = document.body;
      el.insertBefore(clone, el.firstChild);
    }
  }

  renderFieldError(field, messages) {
    console.log(field)

    const input = this.element.querySelector(`[name$="[${field}]"]`)
        || this.element.querySelector(`[data-field="${field}"`);

    console.log(input)

    const fieldContainer = input.closest(".field-section");
    let error = fieldContainer.querySelector(".error");

    if (!error) {
      error = this.buildError(messages);
    }

    fieldContainer.appendChild(error);
  }

  buildError(messages) {
    const error = document.createElement("div");

    error.classList.add("error");
    error.setAttribute("data-controller", "alert");
    error.setAttribute("data-target", "alert.fieldError");

    error.innerHTML = "<ul></ul>";

    const errorList = error.querySelector("ul");

    messages.forEach((msg) => {
      const item = document.createElement("li");

      item.textContent = msg;

      if (!errorList.contains(item)) {
        errorList.appendChild(item);
      }
    });

    return error;
  }
}
