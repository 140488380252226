import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["close"]

  connect() {
    this.modal = document.querySelector(this.data.get("selector")) || this.element
    this.overlay = document.querySelector("#modal_overlay");

    this.overlay.addEventListener("click", ()=> {
      this.close()
    })

    if (!this.data.get("hidden")) {
      this.open();
    }
  }

  open() {
    this.toggle()
  }

  // I am just removing the modal for right now because of my stupid FORM logic regad multiple forms...
  // It prevents me from having multiple forms of the same selector/id on the page
  // I will have to develop some logic around how to appropriatly replace/or render new modals
  close() {
    this.overlay.classList.add("hidden");
    this.modal.remove();
  }

  toggle() {
    this.modal.classList.toggle("hidden");
    this.overlay.classList.toggle("hidden");
  }
}
