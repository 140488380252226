import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["temp", "fieldError"]

  connect() {
    if (this.hasTempTarget) {
      this.processTempAlert(this.tempTarget);
    }

    if (this.hasFieldErrorTarget) {
      this.initFieldError();
    }
  }

  processTempAlert(alert) {
    setTimeout(() => {
      this.closeAlert(alert);
      // TODO: refactor this so it waits for ".visible?" - to prevent from dead time
      setTimeout(() => {
        this.removeAlert(alert);
      }, 1000);
    }, 3000);
  }

  initFieldError() {
    this.fieldErrorTargets.forEach((error) => {
      const input = error.closest(".field-section").querySelector("input");

      input.addEventListener("input", () => {
        if (input.value === "") {
          error.remove();
        }
      });
    });
  }

  closeAlert(alert) {
    alert.querySelector(".close").click();
  }

  removeAlert(alert) {
    alert.remove();
  }
}
