import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template"]

  connect() {
  }

  duplicate() {
    var dup = this.templateTarget.cloneNode(true)
    this.templateTarget.after(dup)

    this.toggleContent(dup);

    dup.querySelectorAll("input").forEach((input) => {
      this.toggleInput(input);
    })
  }

  toggleInput(input) {
    input.disabled = !input.disabled
  }

  toggleContent(content) {
    if (content.style.display === "none") {
      content.style.display = "block";
    } else {
      content.style.display = "none";
    }
  }
}
